<template>
  <div>
    <form-summary
      v-if="!isValid"
      class="form-errors alert alert-danger"
      :validator="$v.form"
      :attributes="attributes"
    />

    <CForm>
      <div class="d-flex flex-column">
        <!-- btw_nummer -->
        <div class="mb-0 d-flex flex-column flex-sm-row">
          <CInput
            class="w-100 mr-2"
            label="VAT (BTW) Number:"
            placeholder="VAT (BTW) Number"
            :lazy="false"
            :value.sync="$v.form.btw_nummer.$model"
            :disabled="invoiceCount > 0"
            @focusout="fixVat"
            :isValid="checkIfValid('btw_nummer')"
          >
            <template #append>
              <CButton
                color="info"
                :disabled="checkSpinner || !$v.form.btw_nummer.$model || !isValidVat(form.btw_nummer)"
                @click="getCompanyInfo"
              >
                <CSpinner size="sm" v-if="checkSpinner" />
                Get Company Info
              </CButton>
            </template>
          </CInput>

          <div class="form-group w-100">
            <template v-if="form.btw_nummer && isValidVat(form.btw_nummer)">
              <label class="d-none d-sm-block">&nbsp;</label>
              <a :href="`https://kbopub.economie.fgov.be/kbopub/toonondernemingps.html?lang=NL&ondernemingsnummer=${form.btw_nummer}`"
                target="blank"
                class="btn p-1"
              >
                <img src="@/assets/images/logo-kbo.jpg" height="28" />
              </a>
            </template>
          </div>
        </div>

        <div class="mb-3">
          <CAlert :show="allRestaurantByVAT.length > 0" color="info">
            <CIcon :height="24" name="cil-info" class="mr-2" />
            <strong>Restaurants that have {{ form.btw_nummer }} VAT (BTW) Number:</strong>
            <ul class="pl-3 mt-2 mb-0">
              <li v-for="restaurant in allRestaurantByVAT" :key="restaurant.value">
                <CLink :to="{ name: 'Edit Restaurant', params: { id: restaurant.value }}">
                  {{ restaurant.label }}
                </CLink>
              </li>
            </ul>
          </CAlert>
        </div>

        <!-- firma_naam, firma_type -->
        <div class="mb-3 d-flex flex-column flex-md-row">
          <CInput
            class="w-100 mr-2"
            label="Company Name:"
            placeholder="Company Name"
            :lazy="false"
            :value.sync="$v.form.firma_naam.$model"
          />
          <CInput
            class="w-100"
            label="Company Type:"
            placeholder="Company Type"
            :lazy="false"
            :value.sync="$v.form.firma_type.$model"
          />
        </div>

        <!-- firm address section -->
        <section class="address mb-3">

          <!-- Copy from restaurant address  -->
          <div class="form-group w-100">
            <CLink
              color="info"
              @click="copyFromRestaurantAddress"
            >
              <span v-if="copySucceeded.restaurantAddress"><CIcon name="cil-check-alt" /> Copied!</span>
              <span v-else><CIcon name="cil-copy" /> Copy From Restaurant Address</span>
            </CLink>
          </div>

          <!-- Verify Firm Address From Google -->
          <CInput
            id="verify_firm_address"
            :value.sync="verified_firm_address"
            class="w-100"
            label="Verify Company Address From Google:"
            placeholder="Enter a location"
            :lazy="false"
          >
            <template #prepend>
              <CButton color="info"><CIcon name="cil-location-pin"/></CButton>
            </template>
          </CInput>

          <!-- firma_mz_adres -->
          <div class="d-flex flex-column flex-md-row">
            <CTextarea
              class="w-100"
              rows="2"
              label="Address:"
              placeholder="Street name and house number"
              :lazy="false"
              :value.sync="$v.form.firma_mz_adres.$model"
            />
          </div>

          <!-- firma_mz_postcode, firma_mz_city -->
          <div class="d-flex flex-column flex-md-row">
            <CInput
              class="w-100 mr-2"
              label="Postal Code:"
              placeholder="Zip or Postal Code"
              :lazy="false"
              :value.sync="$v.form.firma_mz_postcode.$model"
            />
            <CInput
              class="w-100 mr-2"
              label="City:"
              placeholder="City"
              :lazy="false"
              :value.sync="$v.form.firma_mz_city.$model"
            />
          </div>

        </section>

        <!-- bank_rekeninghouder, bank_naam, bank_rekeningnummer -->
        <div class="mb-3 d-flex flex-column flex-md-row">
          <CInput
            class="w-100 mr-md-2"
            label="Bank Account Holder:"
            placeholder="Bank Account Holder"
            :lazy="false"
            :value.sync="$v.form.bank_rekeninghouder.$model"
          />

          <div class="w-100 d-flex flex-row">
            <CInput
              class="w-100 mr-2"
              label="Bank Name:"
              placeholder="Bank Name"
              :lazy="false"
              :value.sync="$v.form.bank_naam.$model"
            />
            <CInput
              class="w-100"
              label="Bank Account Number (IBAN):"
              placeholder="Bank Account Number (IBAN)"
              :lazy="false"
              :value.sync="$v.form.bank_rekeningnummer.$model"
              :isValid="checkIfValid('bank_rekeningnummer')"
            />
          </div>
        </div>

        <!-- eigenaar1_naam, eigenaar1_gsm, eigenaar1_email -->
        <h6 class="border-bottom pb-1">Contact Person 1:</h6>
        <div class="mb-3 d-flex flex-column flex-md-row">
          <CInput
            class="w-100 mr-md-2"
            label="Name:"
            placeholder="Name"
            :lazy="false"
            :value.sync="$v.form.eigenaar1_naam.$model"
          />

          <div class="w-100 d-flex flex-row">
            <div class="form-group w-100 mr-2">
              <label class="d-block">
                Mobile Phone:
                <CLink
                  v-c-tooltip="'Copy from Restaurant Mobile Number'"
                  color="info"
                  @click="copyFromRestaurantGsm"
                >
                  <span v-if="copySucceeded.restaurantGsm"><CIcon name="cil-check-alt" /> Copied!</span>
                  <span v-else><CIcon name="cil-copy" /></span>
                </CLink>
              </label>
              <CInput
                class="w-100 mr-2"
                placeholder="Mobile Phone"
                :lazy="false"
                :value.sync="$v.form.eigenaar1_gsm.$model"
              />
            </div>

            <div class="form-group w-100 mr-2">
              <label class="d-block">
                Email:
                <CLink
                  v-c-tooltip="'Copy from Restaurant Email'"
                  color="info"
                  @click="copyFromRestaurantEmail"
                >
                  <span v-if="copySucceeded.restaurantEmail"><CIcon name="cil-check-alt" /> Copied!</span>
                  <span v-else><CIcon name="cil-copy" /></span>
                </CLink>
              </label>
              <CInput
                class="w-100 email"
                placeholder="Email"
                :lazy="false"
                :value.sync="$v.form.eigenaar1_email.$model"
              />
            </div>
          </div>
        </div>

        <!-- eigenaar2_naam, eigenaar2_gsm, eigenaar2_email -->
        <h6 class="border-bottom pb-1">Contact Person 2:</h6>
        <div class="mb-3 d-flex flex-column flex-md-row">
          <CInput
            class="w-100 mr-md-2"
            label="Name:"
            placeholder="Name"
            :lazy="false"
            :value.sync="$v.form.eigenaar2_naam.$model"
          />

          <div class="w-100 d-flex flex-row">
            <CInput
              class="w-100 mr-2"
              label="Mobile Phone:"
              placeholder="Mobile Phone"
              :lazy="false"
              :value.sync="$v.form.eigenaar2_gsm.$model"
            />
            <CInput
              class="w-100 email"
              label="Email:"
              placeholder="Email"
              :lazy="false"
              :value.sync="$v.form.eigenaar2_email.$model"
            />
          </div>
        </div>

        <!-- referenties -->
        <div class="d-flex flex-column flex-md-row">
          <CTextarea
            class="w-100 mr-md-2"
            rows="2"
            label="References:"
            placeholder="References"
            :lazy="false"
            :value.sync="$v.form.referenties.$model"
          />

          <CSelect
            description="Please select the originator of the restaurant."
            class="w-100"
            label="Originator:"
            placeholder="Please select.."
            :lazy="false"
            :value.sync="$v.form.originator.$model"
            :options="$globalVar.originators"
            :isValid="checkIfValid('originator')"
          />
        </div>
      </div>
    </CForm>
  </div>

</template>

<script>
import { validationMixin } from "vuelidate"
import { helpers, email } from "vuelidate/lib/validators"
import _debounce from 'lodash/debounce';

const validIBAN = (value) => !helpers.req(value) || IBAN.isValid(value)

export default {
  name: 'RestaurantWizardStep6',
  data() {
    return {
      loading: false,
      form: {},
      oldBtw: "",
      invoiceCount : 0,
      allRestaurantByVAT: [],
      checkSpinner: false,

      // Google Maps API Autocomplete
      verified_firm_address: '',
      autocomplete_firm_address: null,

      // Copy To
      copySucceeded: {
        restaurantAddress: null,
        restaurantGsm: null,
        restaurantEmail: null
      },

      // Vuelidate-error-extractor
      attributes: {
        bank_rekeningnummer: 'Bank Account Number (IBAN)',
        eigenaar1_email: 'Contact Person 1 email',
        eigenaar2_email: 'Contact Person 2 email',
        btw_nummer: 'VAT (BTW) Number'
      },
    }
  },

  mixins: [validationMixin],
  validations: {
    form: {
      btw_nummer: {
        checkVAT: function (value) {
          return this.isValidVat(value);
        },
      },
      firma_naam: {},
      firma_type: {},
      firma_mz_adres: {},
      firma_mz_postcode: {},
      firma_mz_city: {},
      bank_naam: {},
      bank_rekeningnummer: { validIBAN },
      bank_rekeninghouder: {},
      eigenaar1_naam: {},
      eigenaar1_gsm: {},
      eigenaar1_email: {
        email: function () {
          if (!this.form.eigenaar1_email) {
            return true;
          }
          const emails = this.form.eigenaar1_email.split(";");
          return emails.map(email).reduce((res, val) => res && val, true);
        },
      },
      eigenaar2_naam: {},
      eigenaar2_gsm: {},
      eigenaar2_email: {
        email: function () {
          if (!this.form.eigenaar2_email) {
            return true;
          }
          const emails = this.form.eigenaar2_email.split(";");
          return emails.map(email).reduce((res, val) => res && val, true);
        },
      },
      referenties: {},
      originator: {},
    }
  },

  watch: {
    'form.eigenaar1_email': function(newVal, oldVal) { this.form.eigenaar1_email = newVal ? newVal.toLowerCase() : newVal },
    'form.eigenaar2_email': function(newVal, oldVal) { this.form.eigenaar2_email = newVal ? newVal.toLowerCase() : newVal },
    'form.btw_nummer': function(newVal, oldVal) {
      if (newVal && newVal.length == 10) {
        this.debouncedGetRestaurantsByVAT()
      } else {
        this.allRestaurantByVAT = []
      }
    },
  },

  async beforeMount() {
    this.debouncedGetRestaurantsByVAT = _debounce(async () => {
      await this.getRestaurantsByVAT();
    }, 1000);
  },

  async mounted() {
    this.form = this.getForm();

    // Google Maps API Autocomplete: Place Autocomplete Address Form
    this.autocomplete_firm_address = new google.maps.places.Autocomplete(
      document.getElementById("verify_firm_address"),
      {
        componentRestrictions: { country: [this.country?.iso2] },
        fields: ["address_components", "geometry", "formatted_address"],
        types: ["address"],
      }
    );
    this.autocomplete_firm_address.addListener("place_changed", this.setFirmPlace);
    //(end) -----


    this.$nextTick(() => {
      this.$emit("loaded");
    });
  },

  computed: {
    isValid() { return !this.$v.form.$invalid },
    isDirty() { return this.$v.form.$anyDirty },
    country() {
      return this.authCountries.find((item) => item.value == (this.form.countryId || this.$store.state.countryId));
    },
  },

  methods: {
    checkIfValid (fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      return !(field.$invalid);
      // return !(field.$invalid || field.$model === "");
    },

    validate () {
      this.$v.$touch()
    },

    getForm() {
      const storedForm = localStorage.getItem('pending-restaurant');

      if (storedForm) {
        var form = JSON.parse(storedForm);

        this.oldBtw = form.btw_nummer = form.btw_nummer;
        form.firma_naam = form.firma_naam;
        form.firma_type = form.firma_type;
        form.firma_mz_adres = form.firma_mz_adres;
        form.firma_mz_postcode = form.firma_mz_postcode;
        form.firma_mz_city = form.firma_mz_city;
        form.bank_naam = form.bank_naam;
        form.bank_rekeningnummer = form.bank_rekeningnummer;
        form.bank_rekeninghouder = form.bank_rekeninghouder;
        form.eigenaar1_naam = form.eigenaar1_naam;
        form.eigenaar1_gsm = form.eigenaar1_gsm;
        form.eigenaar1_email = form.eigenaar1_email;
        form.eigenaar2_naam = form.eigenaar2_naam;
        form.eigenaar2_gsm = form.eigenaar2_gsm;
        form.eigenaar2_email = form.eigenaar2_email;
        form.referenties = form.referenties;
        form.originator ??= 0; // form.originator = typeof form.originator === 'undefined' ? 0 : form.originator;

        return form;
      } else {
        this.$emit("reset");
      }
    },

    async next() {
      if (!this.isValid) {
        window.scroll({top: 180, behavior: 'smooth'});
        this.validate();
        return;
      }

      localStorage.setItem('pending-restaurant', JSON.stringify(this.form));
      this.$emit("next", true);
    },

    async previous() {
      localStorage.setItem('pending-restaurant', JSON.stringify(this.form));
      this.$emit("previous", true);
    },


    // VAT Number
    fixVat(event) {
      const fixedValue = this.$globalFunc.fixVAT(event.target.value);
      event.target.value = fixedValue;
    },
    isValidVat(vatNumber) {
      const result = this.$globalFunc.checkVAT(vatNumber, this.country.value);
      return result || !helpers.req(vatNumber) || false;
    },


    async getRestaurantsByVAT() {
      await this.$axios.get(this.$backend.RESTAURANT.GET_ALL_NAME, { params: { 'vat_number': this.form.btw_nummer } })
        .then((response) => (this.allRestaurantByVAT = response.data));
    },
    async VATvalidation() {
      return await this.$axios
        .post(this.$backend.RESTAURANT.VAT_VALIDATION, {
          country_code: this.country?.iso2.toUpperCase(),
          vat_number: this.form.btw_nummer,
        })
    },
    async getCompanyInfo() {
      this.checkSpinner = true

      await this.VATvalidation()
        .then(({data}) => {
            if (data.response) {

            const name = data.response.name.replace(/\u00a0/g, ' ');
            const address = data.response.address.replace(/\u00a0/g, ' ').trim();

            const [firmType, ...firmName] = name.includes('|')
              ? name.split('|').map(part => part.trim())
              : name.split(' ');

            this.form.firma_type = firmType;
            this.form.firma_naam = firmName.join(" ");


            // address: "Zeven Septemberlaan 7\n3550 Heusden-Zolder"
            const [firmAddress, postcodeAndCity] = address.split('\n');
            const [firmPostcode, ...firmCity] = postcodeAndCity.split(' ');

            this.form.firma_mz_adres = firmAddress;
            this.form.firma_mz_postcode = firmPostcode;
            this.form.firma_mz_city = firmCity.join(" ");

            this.$toast.success(`Valid VAT number`);
          } else {
            this.$toast.error(`Invalid VAT (BTW) number!`);
          }
        })
        .catch((error)=>{
          this.$toast.error(`${error.response.data.message}`);
        })
        .finally(() => {
          this.checkSpinner = false
        });
    },


    copyFromRestaurantAddress() {
      this.form.firma_mz_adres = this.form.restaurant_address;
      this.form.firma_mz_postcode = this.form.restaurant_postcode;
      this.form.firma_mz_city = this.form.restaurant_city;

      this.copySucceeded.restaurantAddress = true;
      setTimeout(() => this.copySucceeded.restaurantAddress = false, 5000);
    },
    copyFromRestaurantGsm() {
      this.form.eigenaar1_gsm = this.form.restaurant_gsm;

      this.copySucceeded.restaurantGsm = true;
      setTimeout(() => this.copySucceeded.restaurantGsm = false, 3000);
    },
    copyFromRestaurantEmail() {
      this.form.eigenaar1_email = this.form.email;

      this.copySucceeded.restaurantEmail = true;
      setTimeout(() => this.copySucceeded.restaurantEmail = false, 3000);
    },
    setFirmPlace() {
      // Get the place details from the autocomplete object.
      const place = this.autocomplete_firm_address.getPlace();

      let street_number = "";
      let route = "";
      let postcode = "";
      let locality = "";

      // Get each component of the address from the place details,
      // and then fill-in the corresponding field on the form.
      // place.address_components are google.maps.GeocoderAddressComponent objects
      // which are documented at http://goo.gle/3l5i5Mr
      for (const component of place.address_components) {
        const componentType = component.types[0];

        switch (componentType) {
          case "street_number": {
            street_number = component.long_name;
            break;
          }

          case "route": {
            route = component.short_name;
            break;
          }

          case "postal_code": {
            postcode = `${component.long_name}${postcode}`;
            break;
          }

          case "postal_code_suffix": {
            postcode = `${postcode}-${component.long_name}`;
            break;
          }

          case "locality": {
            locality = component.long_name;
            break;
          }

          // case "administrative_area_level_1": {
          //   console.log("state: ", component.short_name);
          //   break;
          // }

          // case "country": {
          //   console.log("country: ", component.long_name);
          //   console.log("country: ", component.short_name);
          //   break;
          // }
        }
      }

      // street_number:  25
      // route:  Sint-Willibrordusplein
      // locality:  Heusden-Zolder
      // state:  Vlaams Gewest
      // country:  Belçika
      // country:  BE
      // address1:  Sint-Willibrordusplein 25
      // postcode:  3550
      // latitude:  51.0331523
      // longitude:  5.2814117

      this.verified_firm_address = place.formatted_address;

      this.form.firma_mz_adres = `${route} ${street_number}`;
      this.form.firma_mz_postcode = postcode.replace(/\D/g, "");
      this.form.firma_mz_city = locality;
    },
  }
}
</script>

<style scoped>
section.address {
  background: #F8F0DF;
  padding: 1rem;
  border-radius: 0.5rem;
}
</style>

<style>
input[type="radio"] + label {
  font-weight: normal;
}

.email.form-group > input {
  text-transform: lowercase;
}
.email.form-group > input::-webkit-input-placeholder,
.email.form-group > input:-moz-placeholder,
.email.form-group > input::-moz-placeholder,
.email.form-group > input:-ms-input-placeholder,
.email.form-group > input::placeholder {
  text-transform: none;
}
</style>
